import { ContentData } from '@/episerver/models'

function getInnerGridType(block: any): string {
    return block?.innerGridType || 'grid grid--contained'
}

function getOuterGridType(block: any): string {
    if (useOuterGrid(block) && block?.outerGridType === undefined) {
        return 'grid grid--fluid'
    }
    return block?.outerGridType || 'grid'
}

function getMarginClass(block: any): string {
    const cssClasses = []

    if (block.marginBottom !== 'default') {
        cssClasses.push(`mb-${block.marginBottom}`)
    } else {
        cssClasses.push('mb-3') // default bottom margin
    }

    if (block.marginTop !== 'default') {
        cssClasses.push(`mt-${block.marginTop}`)
    }

    return cssClasses.join(' ')
}

function useOuterGrid(block: any): boolean {
    return !!block?.useOuterGrid
}

function getInnerBackgroundColor(block: any): string {
    return block?.innerBackgroundColor || ''
}

function getOuterBackgroundColor(block: any): string {
    return block?.outerBackgroundColor || ''
}

function getGridSizeClasses(block: any, contentType?: any): string {
    if (block === undefined) return ''

    let text = ''

    if (block.gridSize && block.gridSize !== 'None') {
        text += block.gridSize
    } else {
        if (contentType === 'ParagraphBlock' || contentType === 'MediaBlock') {
            // defaults for certain blocks
            text += 'span8 move2 span10--md move1--md'
        } else {
            text += 'span12' // all others, no display setting applied
        }
    }

    if (block.gridMove && block.gridSize !== '') {
        text += ` ${block.gridMove}`
    }

    if (block.tabletGridSize && block.tabletGridSize !== 'None' && block.gridSize !== '') {
        text += ` ${block.tabletGridSize}--md`
    }

    if (block.tabletGridMove && block.gridSize !== '') {
        text += ` ${block.tabletGridMove}--md`
    }

    if (block.mobileGridSize && block.mobileGridSize !== 'None') {
        text += ` ${block.mobileGridSize}--sm`
    }

    if (block.mobileGridMove) {
        text += ` ${block.mobileGridMove}--sm`
    }

    return text
}

// eslint-disable-next-line
function getModelFromBlock(block: any): ContentData {
    return block.content
}

export {
    getInnerGridType,
    getOuterGridType,
    getMarginClass,
    useOuterGrid,
    getInnerBackgroundColor,
    getOuterBackgroundColor,
    getGridSizeClasses,
    getModelFromBlock,
}
